import React from "react";
import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";
import "../static/styles/main.css";
const Container = styled.div`
  font-family: "Avenir";
  max-width: 767px;
  margin: auto;
  padding: 0 10px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Miriam Libre";
  }
`;
function Layout(props) {
  return (
    <Container>
      <Header />
      <main>{props.children}</main>
      <Footer />
    </Container>
  );
}
export default Layout;
