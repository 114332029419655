import React from "react";
import styled from "styled-components";

const BoxContainer = styled.div`
  border: 1px solid #333;
  border-radius: 10px;
  padding: 1rem;
  position: relative;
  background-color: white;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid #333;
    border-radius: 0 17px 10px 17px;
    z-index: -1;
    top: 5px;
    left: 5px;
    background-color: yellowgreen;
  }
`;

export default function Box(props) {
  return <BoxContainer style={props.style}>{props.children}</BoxContainer>;
}
