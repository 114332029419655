import React from "react";
import styled from "styled-components";
import Box from "./Box";
import Logo from "./Logo";
const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NavLink = styled.a`
  color: yellowgreen;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const NavHeading = styled.h3`
  margin: 0;
  display: flex;
  align-items: center;
`;
function Header(props) {
  return (
    <Box>
      <Navbar>
        <NavLink role="heading" href="/">
          <NavHeading>
            <Logo /> <span style={{ marginLeft: "10px" }}>Gulam Hussain</span>
          </NavHeading>
        </NavLink>
      </Navbar>
    </Box>
  );
}
export default Header;
