import React from "react";
import styled from "styled-components";
const FooterContainer = styled.footer`
  padding: 4rem 0;
  text-align: center;
`;

export default function Footer() {
  return (
    <FooterContainer>
      <h4>
        Say Hi on <a href="https://twitter.com/ghussaindars">twitter</a> and
        <a href="mailto:ghussaindars@gmail.com"> email</a>
      </h4>
      <p>Designed and developed by Gulam Hussain.</p>
      <p>Built with Gatsby. Hosted on Netlify.</p>
    </FooterContainer>
  );
}
