import React from "react";
import styled, { keyframes } from "styled-components";
const outerRotationKeyFrame = keyframes`0%{transform: translate(-50%, -50%) rotate(0)} 100%{transform: translate(-50%, -50%) rotate(360deg)}`;
const innerRotationKeyFrame = keyframes`0%{transform: translate(-50%, -50%) rotate(120deg)} 100%{transform: translate(-50%, -50%) rotate(480deg)}`;

const OuterCircle = styled.div`
  height: 30px;
  width: 30px;
  border: 2px solid #333;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &::before {
    height: 5px;
    border: 1px solid white;
    width: 5px;
    background-color: #9acd32;
    border-radius: 50%;
    position: absolute;
    content: "";
    left: 0;
  }
`;
const InnerCircle = styled(OuterCircle)`
  height: 15px;
  width: 15px;
  transform: translate(-50%, -50%) rotate(120deg);
  &::before {
    height: 3px;
    border: 1px solid white;
    width: 3px;
    left: -2px;
  }
`;
const InnerCircle1 = styled(InnerCircle)`
  height: 7px;
  width: 7px;
  border: none;
  animation: none;
  background-color: #9acd32;
  &::before {
    display: none;
  }
`;
const AnimationContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  /* &:hover {
    ${OuterCircle} {
      animation: ${outerRotationKeyFrame} 4s infinite linear forwards;
    }
    ${InnerCircle} {
      animation: ${innerRotationKeyFrame} 2s infinite linear forwards;
    }
  } */
`;
function Logo() {
  return (
    <AnimationContainer>
      <OuterCircle />
      <InnerCircle />
      <InnerCircle1 />
    </AnimationContainer>
  );
}
export default Logo;
